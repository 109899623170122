<template>
  <div class="service-multi-point-resume border p-4 rounded mt-3">
    <div
      class="d-flex justify-content-between"
      :class="[{ 'pr-4 mr-1': !enableEditRate }]"
    >
      <div class="text-xl font-weight-bold">Total</div>
      <div class="d-flex align-items-center">
        <div class="text-xl font-weight-bold cost-val mr-3">
          {{ cost }} {{ currency }}
        </div>
        <z-icon
          v-if="enableEditRate"
          name="Edit"
          size="24"
          role="button"
          @click.native="editRate"
        />
      </div>
    </div>
    <div class="small pr-5">
      <div class="d-flex justify-content-between mt-2">
        <div>Subtotal</div>
        <div class="distance-val">{{ subtotal }} {{ currency }}</div>
      </div>
      <div class="d-flex justify-content-between mt-2">
        <div>{{ taxMessage }}</div>
        <div class="time-val">{{ tax }} {{ currency }}</div>
      </div>
      <div class="d-flex justify-content-between mt-2">
        <div>Distancia</div>
        <div class="distance-val">{{ rates.km }} kms</div>
      </div>
      <div class="d-flex justify-content-between mt-2">
        <div>Tiempo</div>
        <div class="time-val">{{ rates.minutes }} min</div>
      </div>
    </div>
    <hr class="mb-2 mt-3" />
    <div class="d-flex justify-content-between py-2">
      <div>
        <div class="font-weight-bold">Mensajero</div>
        <div class="d-flex align-items-center mt-2">
          <div class="rounded mr-2 text-secondary text-xs">
            <font-awesome-icon icon="helmet" flip="horizontal" size="2x" />
          </div>
          <div>
            <div class="text-secondary small">
              {{ !driver.id ? "No asignado" : driver.name }}
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex">
        <div class="mr-2">
          <font-awesome-icon
            v-show="vehicle === vehicleTypes.NUM_MOTORCYCLE"
            icon="zubut-motorcycle"
            size="lg"
          />
          <font-awesome-icon
            v-show="vehicle === vehicleTypes.NUM_ECO"
            icon="zubut-bike"
            size="lg"
          />
          <font-awesome-icon
            v-show="vehicle === vehicleTypes.NUM_CAR"
            icon="zubut-car"
            size="lg"
          />
        </div>
        <div class="text-secondary small">
          {{ vehicle === -1 ? "Por determinar" : vehicleName }}
        </div>
      </div>
    </div>
    <hr class="my-2" />
    <express-creation-map class="mt-3" />
    <div>
      <div
        v-for="(destination, index) in formattedDestinations"
        :key="`destination-${index}`"
        class="d-flex mt-2"
      >
        <div class="pt-1 mr-3" :class="{ 'text-primary': index !== 0 }">
          <font-awesome-icon icon="hexagon-bullet" size="lg" />
        </div>
        <div class="pt-2 small">
          <div class="font-weight-bold">{{ destination.name }}</div>
          <div class="mt-2">{{ destination.address.address }}</div>
          <div>T: {{ destination.phone }}</div>
        </div>
      </div>
    </div>
    <div>
      <div
        v-if="!formattedDestinations[0] || !formattedDestinations[0].name"
        class="d-flex mt-2"
      >
        <div class="pt-1 mr-3">
          <font-awesome-icon icon="hexagon-bullet" size="lg" />
        </div>
        <div class="pt-2 small">
          <div class="font-weight-bold">Origen</div>
          <div class="mt-2 text-secondary">Sin dirección</div>
        </div>
      </div>
      <div
        v-if="!formattedDestinations[1] || !formattedDestinations[1].name"
        class="d-flex mt-2"
      >
        <div class="pt-1 mr-3 text-primary">
          <font-awesome-icon icon="hexagon-bullet" size="lg" />
        </div>
        <div class="pt-2 small">
          <div class="font-weight-bold">Destino 1</div>
          <div class="mt-2 text-secondary">Sin dirección</div>
        </div>
      </div>
    </div>
    <express-creation-rate :show.sync="showRateModal" />
  </div>
</template>

<script>
import VehicleTypes from "@/constants/vehicles/type";
import ExpressCreationMap from "./ExpressCreationMap";
import { formatMoney } from "@zubut/common/src/utils/money";
import ExpressCreationRate from "./ExpressCreationRate.vue";

export default {
  name: "ExpressCreationResume",

  components: {
    ExpressCreationMap,
    ExpressCreationRate
  },

  data() {
    return {
      vehicleTypes: VehicleTypes,
      showRateModal: false
    };
  },

  computed: {
    formattedDestinations() {
      return this.$store.getters["express/getDestinations"]
        .map(el => el.data)
        .filter(el => el.name);
    },
    placeHolderDestinations() {
      return this.$store.getters["express/getDestinations"].map(el => el.data);
    },
    vehicle() {
      return this.$store.state.express.vehicleType;
    },
    vehicleName() {
      return VehicleTypes.get[this.vehicle];
    },
    rates() {
      return this.$store.state.express.rates;
    },
    driver() {
      return this.$store.state.express.driver;
    },
    cost() {
      return formatMoney(this.rates.cost);
    },
    subtotal() {
      return formatMoney(this.rates.subtotal);
    },
    tax() {
      return formatMoney(this.rates.tax);
    },
    client() {
      return this.$store.getters["express/getClient"];
    },
    enableEditRate() {
      return this.client != null;
    },
    currency() {
      return this.rates.currency;
    },
    taxMessage() {
      return this.rates?.currency?.toUpperCase() === "MXN"
        ? "IVA"
        : "Cargo por servicio";
    }
  },

  methods: {
    rateChange({ km, minutes }) {
      this.$emit("rate-change", { km, minutes });
    },
    editRate() {
      this.showRateModal = true;
    }
  }
};
</script>

<style lang="scss">
.service-multi-point-resume {
  .map {
    height: 300px;
  }
}
</style>
