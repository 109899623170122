
























































import Vue from "vue";
import ZResponsiveModal from "@zubut/common/src/components/ZResponsiveModal.vue";
import ServiceType from "@zubut/common/src/constants/services/type";
import ServiceTypeAvatar from "@zubut/common/src/components/ServiceTypeAvatar.vue";

export default Vue.extend({
  name: "ZDeliveryCreationModal",

  components: {
    ServiceTypeAvatar,
    ZResponsiveModal
  },

  props: {
    enableCreate: {
      type: Boolean,
      default: false
    },
    creating: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      required: true
    },
    subtitle: {
      type: String,
      default: ""
    },
    serviceType: {
      type: Number,
      required: true,
      validator: val =>
        [ServiceType.NUM_MULTI_POINT, ServiceType.NUM_PARCEL_DELIVERY].indexOf(
          val
        ) > -1
    },
    hideClose: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    createButtonText(): string {
      return this.serviceType === ServiceType.NUM_MULTI_POINT
        ? "Crear entrega"
        : "Crear guía";
    },
    disableCreateButton(): boolean {
      return this.enableCreate === false || this.creating === true;
    }
  }
});
