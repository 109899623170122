












































import Vue from "vue";
import ZModal from "@zubut/common/src/components/ZModal.vue";
import currency from "currency.js";
import * as mutations from "@/store/modules/express/mutations-types";
import { formatMoney } from "@/utils/money";

export default Vue.extend({
  name: "ExpressCreationRate",

  components: {
    ZModal
  },

  props: {
    show: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      subtotal: 0,
      tax: 0,
      currency: "",
      taxMessage: "IVA"
    };
  },

  computed: {
    total(): Number {
      return currency(this.subtotal).add(this.tax).value;
    },
    formattedTax(): String {
      return formatMoney(this.tax) || `$ 0 ${this.currency}`;
    },
    formattedTotal(): String {
      return formatMoney(this.total) || `$ 0 ${this.currency}`;
    },
    client(): String {
      return this.$store.getters["express/getClient"];
    }
  },

  watch: {
    show: {
      immediate: true,
      handler(val: boolean) {
        if (val == true) {
          this.subtotal = this.$store.state.express.rates.subtotal;
          this.tax = this.$store.state.express.rates.tax;
          this.currency = this.$store.state.express.rates.currency;
          this.taxMessage =
            this.$store.state.express.rates.currency.toUpperCase() === "MXN"
              ? "IVA"
              : "Cargo por servicio";
        }
      }
    },
    subtotal() {
      this.tax = currency(this.subtotal).multiply(0.16).value;
    }
  },

  methods: {
    hide() {
      this.$emit("update:show", false);
    },
    handleSave() {
      this.$store.commit(`express/${mutations.SET_RATES_TAX}`, this.tax);
      this.$store.commit(
        `express/${mutations.SET_RATES_SUBTOTAL}`,
        currency(this.subtotal).value
      );
      this.$store.commit(`express/${mutations.SET_RATES_COST}`, this.total);
      this.hide();
    }
  }
});
