
























































import Vue from "vue";
import CollapsableCard from "@zubut/common/src/components/CollapsableCard.vue";
import _isEqual from "lodash/isEqual";
import * as mutations from "@/store/modules/express/mutations-types";

export default Vue.extend({
  name: "ExpressCreationAdditional",

  components: {
    CollapsableCard
  },

  computed: {
    signature: {
      get(): boolean {
        return this.$store.state.express.additionalOptions.signature;
      },
      set(val: boolean): void {
        this.$store.commit(
          `express/${mutations.SET_ADDITIONAL_OPTIONS_SIGNATURE}`,
          val
        );
      }
    },
    restrictedItems: {
      get(): boolean {
        return this.$store.state.express.additionalOptions.restrictedItems;
      },
      set(val: boolean): void {
        this.$store.commit(
          `express/${mutations.SET_ADDITIONAL_OPTIONS_RESTRICTED_ITEMS}`,
          val
        );
      }
    },
    visible: {
      get(): boolean {
        return this.$store.state.express.additionalOptions.visible;
      },
      set(val: boolean): void {
        this.$store.commit(
          `express/${mutations.SET_ADDITIONAL_OPTIONS_VISIBLE}`,
          val
        );
      }
    }
  },

  methods: {
    handleClean() {
      this.signature = false;
      this.restrictedItems = false;
    },
    handleSave() {
      this.visible = false;
    }
  }
});
