










import Vue from "vue";
import ZMapExpandable from "@zubut/common/src/components/ZMapExpandable.vue";

export default Vue.extend({
  name: "ExpressCreationMap",

  components: {
    ZMapExpandable
  },

  computed: {
    destinations(): any[] {
      return this.$store.getters["express/getMapDestinations"];
    },
    vehicleRates(): any {
      return this.$store.getters["express/getVehicleRates"];
    }
  },

  methods: {
    rateChange({ km, minutes }: any) {
      this.$store.dispatch("express/setTimeAndDistance", { km, minutes });
      this.$store.dispatch("express/serviceRate");
    }
  }
});
