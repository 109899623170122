



















































import Vue from "vue";
import ExpressCreationAddress from "./ExpressCreationAddress.vue";
import Clients from "@/services/clients";
import * as mutation from "@/store/modules/express/mutations-types";
import ZFavoriteAddresses from "@zubut/common/src/components/ZFavoriteAddresses.vue";

export default Vue.extend({
  name: "ExpressCreationDestinations",

  components: {
    ExpressCreationAddress,
    ZFavoriteAddresses
  },

  data() {
    return {
      favoriteAddresses: []
    };
  },

  computed: {
    destinations(): any[] {
      return this.$store.getters["express/getDestinations"];
    },
    validDestinations(): any[] {
      return this.destinations.filter((d: any) => d.data?.address?.address);
    },
    showAddDestination(): boolean {
      return this.destinations.length < 5;
    },
    showAddFromFavoriteAddress(): boolean {
      return this.validDestinations.length >= 2 && this.showAddDestination;
    }
  },

  methods: {
    addNewDestination() {
      const index = this.destinations.length;
      this.$store.commit(`express/${mutation.ADD_DESTINATION}`, {
        index,
        data: {
          address: "",
          name: "",
          phone: "",
          information: "",
          id: null
        }
      });
    },
    addFavoriteAddressDestination(favoriteAddresse: any) {
      const index = this.destinations.length;
      this.$store.commit(`express/${mutation.ADD_DESTINATION}`, {
        index,
        data: {
          address: {
            address: favoriteAddresse.address,
            position: favoriteAddresse.position
          },
          phone: favoriteAddresse.phone || "",
          name: favoriteAddresse.name || "",
          information: favoriteAddresse.information || ""
        }
      });
    },
    fetchClientData(clientId: string) {
      if (clientId) {
        Clients.findById({
          id: clientId,
          filter: {
            include: ["wallet", "creditCards", "addresses"]
          }
        } as any).then((client: any) => {
          this.favoriteAddresses = client.addresses;

          if (client?.wallet?.id) {
            this.$store.commit(
              `express/${mutation.SET_WALLET_ID}`,
              client?.wallet?.id || null
            );
          }

          if (client.creditCards) {
            const defaultCard = client.creditCards.find((c: any) => c.default);
            this.$store.commit(
              `express/${mutation.SET_CARD_ID}`,
              defaultCard?.id || null
            );
          }
        });
      }
    },
    handleSave(destinationIndex: number) {
      const updateIndex = destinationIndex + 1;
      this.$store.commit(`express/${mutation.UPDATE_DESTINATION_VISIBILITY}`, {
        index: destinationIndex,
        visible: false
      });
      if (updateIndex < this.destinations.length) {
        this.$store.commit(
          `express/${mutation.UPDATE_DESTINATION_VISIBILITY}`,
          {
            index: updateIndex,
            visible: true
          }
        );
      } else {
        this.$store.commit(`express/${mutation.SET_DRIVER_VISIBLE}`, true);
      }
    }
  }
});
