



















































import Vue from "vue";
import CollapsableCard from "@zubut/common/src/components/CollapsableCard.vue";
import DeliveryMethods from "@zubut/common/src/components/DeliveryMethods.vue";
import VehicleTypes from "@/constants/vehicles/type";
import ZDropdownDrivers from "@/app/components/ZDropdownDrivers.vue";
import * as mutations from "@/store/modules/express/mutations-types";

interface VehicleRates {
  Car: number;
  Motorcycle: number;
  Eco: number;
}

export default Vue.extend({
  components: {
    CollapsableCard,
    DeliveryMethods,
    ZDropdownDrivers
  },

  props: {
    subtitle: {
      type: String,
      default: ""
    }
  },

  data() {
    return {
      selectedVehicle: VehicleTypes.NUM_MOTORCYCLE
    };
  },

  computed: {
    isCarAllowed(): boolean {
      return this.vehicleRates.Car !== null;
    },
    isMotorcycleAllowed(): boolean {
      return this.vehicleRates.Motorcycle !== null;
    },
    isEcoAllowed(): boolean {
      return this.vehicleRates.Eco !== null;
    },
    driver: {
      get(): boolean {
        return this.$store.state.express.driver.id;
      },
      set(val: boolean): void {
        this.$store.commit(`express/${mutations.SET_DRIVER_ID}`, val);
      }
    },
    vehicle: {
      get(): boolean {
        return this.$store.state.express.vehicleType;
      },
      set(val: boolean): void {
        this.$store.commit(`express/${mutations.SET_VEHICLE_TYPE}`, val);
      }
    },
    visible: {
      get(): boolean {
        return this.$store.state.express.driver.visible;
      },
      set(val: boolean): void {
        this.$store.commit(`express/${mutations.SET_DRIVER_VISIBLE}`, val);
      }
    },
    vehicleRates(): VehicleRates {
      return this.$store.getters["express/getVehicleRates"];
    }
  },

  watch: {
    vehicle(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.$store.dispatch("express/serviceRate");
      }
    }
  },

  methods: {
    handleOptionChange(option: any) {
      this.$store.commit(`express/${mutations.SET_DRIVER_NAME}`, option.text);
    }
  }
});
