





































































































































import Vue from "vue";
import ZInputAddress from "@zubut/common/src/components/ZInputAddress.vue";
import ZDropdownClients from "@/app/components/ZDropdownClients.vue";
import { formatPhone, validateName } from "@zubut/common/src/utils/strings";
import _cloneDeep from "lodash/cloneDeep";
import CollapsableCard from "@zubut/common/src/components/CollapsableCard.vue";
import ZFavoriteAddresses from "@zubut/common/src/components/ZFavoriteAddresses.vue";
import * as mutation from "@/store/modules/express/mutations-types";

interface AddressForm {
  id: string | null;
  clientId: string;
  name: string;
  phone: string;
  information: string;
  address: any;
  addressText: string;
  signature: boolean;
  restrictedItems: boolean;
  position: object | null;
  valid: boolean;
  visible: boolean;
}

export default Vue.extend({
  name: "ExpressCreationAddress",

  components: {
    CollapsableCard,
    ZFavoriteAddresses,
    ZDropdownClients,
    ZInputAddress
  },

  props: {
    index: {
      type: Number,
      default: 0
    },
    variant: {
      type: String,
      default: "destination"
    },
    favoriteAddresses: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      showQuickMessage: false,
      quickMessage: null as object | null,
      address: {
        id: null,
        clientId: "",
        name: "",
        phone: "",
        information: "",
        address: "",
        addressText: "",
        signature: false,
        restrictedItems: false,
        position: null,
        valid: true,
        visible: false
      } as AddressForm,
      newAddress: {
        id: null,
        clientId: "",
        name: "",
        phone: "",
        information: "",
        address: "",
        addressText: "",
        signature: false,
        restrictedItems: false,
        position: null,
        valid: false,
        visible: false
      } as AddressForm
    };
  },

  computed: {
    client: {
      get(): string {
        return this.$store.getters["express/getClient"];
      },
      set(val: any) {
        this.$store.commit(`express/${mutation.SET_CLIENT_ID}`, val);
      }
    },

    destination: {
      get(): any {
        return this.$store.getters["express/getAddress"](this.index);
      },
      set(val: any) {
        this.$store.commit(`express/${mutation.UPDATE_DESTINATION}`, val);
      }
    },
    cardTitle(): string {
      if (this.savedAddressIsEmpty) {
        return this.isOrigin
          ? `¿Dónde recolectamos el paquete?`
          : `¿Dónde entregamos el paquete?`;
      }
      return this.isOrigin ? `Recolectar en` : `Entregar a`;
    },

    name(): string {
      return this.newAddress.name;
    },

    isOrigin(): boolean {
      return this.variant === "origin";
    },

    showAdditionalOptions(): boolean {
      return this.isOrigin === true;
    },

    validAddress(): boolean {
      return (
        this.newAddress?.address?.address?.length > 0 &&
        this.newAddress.valid === true
      );
    },

    validPhone(): boolean {
      return this.newAddress.phone.length === 14 ? true : false;
    },

    validName(): boolean {
      return validateName(this.newAddress.name) ? true : false;
    },

    validExtraInformation(): boolean | null {
      return this.newAddress.information.length > 0 ? true : null;
    },

    savedAddress(): any {
      if (this.isOrigin && this.stateAddressIsEmpty && this.defaultAddress) {
        return this.defaultAddress;
      }
      return this.stateAddress;
    },

    savedAddressIsEmpty(): boolean {
      return this.savedAddress?.data?.address ? false : true;
    },

    stateAddress(): any {
      return this.$store.getters["express/getAddress"](this.index);
    },

    stateAddressIsEmpty(): boolean {
      return this.stateAddress?.data?.address ? false : true;
    },

    defaultAddress(): any {
      return null;
    },

    stateAdditionalOptions(): any {
      return this.$store.getters["express/getAdditionalOptions"];
    },

    isAddressFavorite(): boolean {
      return this.newAddress.id ? true : false;
    },

    isFormValid(): boolean {
      if (this.isOrigin && !this.client) {
        return false;
      }
      if (this.validName && this.validPhone && this.validAddress) {
        return true;
      } else {
        return false;
      }
    }
  },

  watch: {
    quickMessage(newMessage) {
      if (newMessage != null) {
        this.showQuickMessage = true;
      } else {
        this.showQuickMessage = false;
      }
    },
    "newAddress.addressText": {
      handler() {
        this.newAddress.valid = false;
      }
    },
    client(newValue, oldValue) {
      if (oldValue !== newValue) {
        this.$emit("client-change", newValue);
      }
    }
  },

  created() {
    this.setStateAddress();
  },

  methods: {
    setStateAddress() {
      if (this.isOrigin && this.stateAddressIsEmpty && this.defaultAddress) {
        this.setDefaultAddress();
      }
      const { id, address, information, name, phone } = this.savedAddress.data;
      this.newAddress.id = id;
      this.newAddress.address = { ...address };
      this.newAddress.addressText = address.address;
      this.newAddress.name = name;
      this.newAddress.phone = formatPhone(phone);
      this.newAddress.information = information;
      this.newAddress.position = address.position
        ? { ...address.position }
        : null;
      this.newAddress.visible = this.destination.visible;
      this.$nextTick(() => {
        this.newAddress.valid = true;
      });
    },

    setDefaultAddress() {
      this.$store.commit(
        `express/${mutation.UPDATE_DESTINATION}`,
        this.defaultAddress
      );
    },

    handleDelete() {
      this.$store.commit(`express/${mutation.DELETE_DESTINATION}`, this.index);
    },

    handleCancel() {
      this.quickMessage = null;
      this.showQuickMessage = false;
      this.cleanDestination();
    },

    handleSave() {
      if (!this.isFormValid) {
        this.showQuickMessage = true;
        this.quickMessage = {
          message: "Comprueba tus datos de contacto",
          type: "error"
        };
      } else {
        this.showQuickMessage = false;
        const formattedDestination = {
          index: this.index,
          visible: this.destination.visible,
          data: {
            address: this.newAddress.address,
            name: this.newAddress.name,
            phone: this.newAddress.phone,
            information: this.newAddress.information
          }
        };
        this.address = { ...this.newAddress };
        this.$store.commit(
          `express/${mutation.UPDATE_DESTINATION}`,
          formattedDestination
        );
        this.$emit("save");
      }
    },

    cleanDestination() {
      this.newAddress = {
        id: null,
        clientId: "",
        name: "",
        phone: "",
        information: "",
        address: "",
        addressText: "",
        signature: false,
        restrictedItems: false,
        position: null,
        valid: false,
        visible: false
      };
      const formattedDestination = {
        index: this.index,
        visible: true,
        data: {
          address: this.newAddress.address,
          name: this.newAddress.name,
          phone: this.newAddress.phone,
          information: this.newAddress.information
        }
      };
      this.address = { ...this.newAddress };
      this.$store.commit(
        `express/${mutation.UPDATE_DESTINATION}`,
        formattedDestination
      );
    },

    handleAutocompleteAddressChange(address: AddressForm) {
      this.newAddress.address = {
        address: address.address,
        position: address.position
      };
      this.newAddress.addressText = address.address;
      this.newAddress.position = address.position;
      this.$nextTick(() => {
        this.newAddress.valid = true;
      });
    },

    handleFavoriteAddressChange(address: AddressForm) {
      this.newAddress.id = address.id;
      this.newAddress.clientId = address.clientId;
      this.newAddress.address = {
        address: address.address,
        position: address.position
      };
      this.newAddress.addressText = address.address;
      this.newAddress.phone = formatPhone(address.phone);
      this.newAddress.name = address.name;
      this.newAddress.information = address.information || "";
      this.$nextTick(() => {
        this.newAddress.valid = true;
      });
    },

    saveDestinationOnFavorites() {
      if (this.isFormValid) {
        const favorite = {
          id: this.newAddress.id,
          clientId: this.newAddress.clientId,
          address: this.newAddress.address.address,
          position: this.newAddress.address.position,
          name: this.newAddress.name,
          phone: this.newAddress.phone,
          information: this.newAddress.information
        };
        this.$store
          .dispatch("user/createOrUpdateFavoriteAddress", favorite)
          .then(res => {
            this.newAddress.id = res.id;
            this.newAddress.clientId = res.clientId;
            this.quickMessage = {
              message: "La dirección se ha actualizado correctamente",
              type: "success"
            };
          })
          .catch(err => {
            this.$captureError(err);
            this.quickMessage = {
              message: "Ocurrió un problema al actualizar la dirección",
              type: "error"
            };
          });
      } else {
        this.showQuickMessage = true;
        this.quickMessage = {
          message: "Comprueba tus datos de contacto",
          type: "error"
        };
      }
    },

    handleClean() {
      this.resetForm();
    },

    resetForm() {
      this.address = {
        id: null,
        clientId: "",
        name: "",
        phone: "",
        information: "",
        address: "",
        addressText: "",
        signature: false,
        restrictedItems: false,
        position: null,
        valid: true,
        visible: false
      };
      this.newAddress = {
        id: null,
        clientId: "",
        name: "",
        phone: "",
        information: "",
        address: "",
        addressText: "",
        signature: false,
        restrictedItems: false,
        position: null,
        valid: false,
        visible: false
      };
    },

    updateDestinationVisibility(visible: boolean) {
      this.$store.commit(`express/${mutation.UPDATE_DESTINATION_VISIBILITY}`, {
        index: this.index,
        visible
      });
    }
  }
});
