










































import Vue from "vue";
import ExpressCreationAdditional from "./ExpressCreationAdditional.vue";
import ExpressCreationDestinations from "./ExpressCreationDestinations.vue";
import ExpressCreationDriver from "./ExpressCreationDriver.vue";
import ExpressCreationResume from "./ExpressCreationResume.vue";
import ServiceType from "@zubut/common/src/constants/services/type";
import ZDeliveryCreationModal from "@zubut/common/src/components/ZDeliveryCreationModal.vue";
import CreateServiceSuccessModal from "@zubut/common/src/components/CreateServiceSuccessModal.vue";
import * as mutations from "@/store/modules/express/mutations-types";

export default Vue.extend({
  name: "ExpressCreation",

  components: {
    CreateServiceSuccessModal,
    ExpressCreationAdditional,
    ExpressCreationDestinations,
    ExpressCreationDriver,
    ExpressCreationResume,
    ZDeliveryCreationModal
  },

  data() {
    return {
      showQuickMessage: false,
      quickMessage: {
        title: "Error",
        message: "",
        type: "error"
      },
      creating: false,
      mulitPointType: ServiceType.NUM_MULTI_POINT,
      isCreating: false,
      showConfirmation: false,
      confirmationAddress: {
        address: "",
        name: "",
        date: null
      } as any
    };
  },

  computed: {
    enableCreate(): boolean {
      return this.$store.getters["express/isFormValid"] && !this.isCreating;
    }
  },

  watch: {
    quickMessage(newMessage) {
      if (newMessage != null) {
        this.showQuickMessage = true;
      } else {
        this.showQuickMessage = false;
      }
    },

    showConfirmation(val) {
      if (val === false) {
        this.handleClose();
      }
    }
  },

  methods: {
    handleClose() {
      this.$store.commit(`express/${mutations.RESET}`);
      this.$router.go(-1);
    },
    handleCreate() {
      this.isCreating = true;
      this.$store
        .dispatch("express/createService")
        .then(res => {
          this.showConfirmation = true;
          const origin = res.destinations[0];
          const scheduled = res.additional.scheduled;
          this.confirmationAddress.name = origin.name;
          this.confirmationAddress.date = scheduled
            ? new Date(scheduled * 1000)
            : null;
        })
        .catch(err => {
          let message = err.message;
          // TODO: Remove this when message is returned in spanish
          if (err.name === "Not enough funds in the wallet") {
            message = "No cuentas con suficientes fondos en tu Wallet";
          }

          this.quickMessage = {
            title: "Error",
            type: "error",
            message
          };
        })
        .finally(() => {
          this.isCreating = false;
        });
    }
  }
});
