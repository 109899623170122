





































import Vue from "vue";
import ZModal from "@zubut/common/src/components/ZModal.vue";
import { format } from "@zubut/common/src/utils/time";

export default Vue.extend({
  components: {
    ZModal
  },

  props: {
    show: {
      type: Boolean,
      required: true,
      default: false
    },
    variant: {
      type: String,
      required: true,
      default: "express",
      validator: value =>
        ["express", "reservation", "parcel", "parcels"].includes(value)
    },
    destination: {
      type: Object,
      default: null
    },
    noCloseOnBackdrop: {
      type: Boolean,
      default: false
    },
    noCloseOnEsc: {
      type: Boolean,
      default: false
    },
    showAddress: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    isSchedluled(): boolean {
      return this.variant === "reservation" || this.destinationDate != null;
    },
    destinationDate(): Date {
      return this.destination?.date;
    },
    date(): string {
      if (this.isSchedluled) {
        return format(
          this.destinationDate,
          "EEEE d 'de' MMM 'del' u, 'a las'  h:mm a"
        );
      } else {
        return "";
      }
    },
    type(): string {
      const variantDirectory: any = {
        express: "Entrega",
        parcel: "Guía",
        parcels: "Guías"
      };
      return variantDirectory[this.variant] || "Reservación";
    },
    verb(): string {
      return this.variant === "parcels" ? "creadas" : "creada";
    },
    destinationName(): string {
      return this.destination?.name || "Origen";
    },
    destinationAddress(): string {
      if (!this.showAddress) return "";
      return this.destination?.address || "Dirección";
    }
  }
});
